import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {cookieAllow, showModal} from "../../Store/appSlice";

const CookieBanner = () => {
    const show = useSelector(state => state.app.modals.cookiePopupShow);
    const cookieAllowIs = useSelector(state => state.app.cookieAllow);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!cookieAllowIs) {
            setTimeout(() => dispatch(showModal({cookiePopupShow: true})), 1500);
        }
    }, [cookieAllowIs, dispatch]);

    const handleClose = () => {
        dispatch(cookieAllow());
    };

    return <div
        className={`fixed z-40 bottom-0 lg:bottom-2 right-0 lg:right-2 md:w-full lg:w-[300px] flex flex-col border shadow-sm rounded-t-xl lg:rounded-xl p-4 md:p-5 dark:bg-blue-500 dark:bg-clip-padding dark:backdrop-filter dark:backdrop-blur-lg dark:bg-opacity-10 dark:border-gray-700 dark:shadow-slate-700/[.7] dark:text-gray-400 transition-all ${show ? `translate-y-0` : `translate-y-[500px]`}`}>
        Мы используем ваши cookie для правильной работы сайта и сбора данных о посещаемости
        <div className="flex justify-end pt-2 w-full">
            <button type="button"
                    className="w-max py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                    onClick={handleClose}
            >
                Хорошо, спасибо
            </button>
        </div>
    </div>
};

export default CookieBanner;