import React from "react";
import {Link as A} from "react-router-dom";

export const Link = ({children, type = "button", onClick = () => {}, to = "", target = ""}) => {
    // eslint-disable-next-line default-case
    switch (type) {
        case "button":
            return <button
                className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                onClick={onClick}>
                {children}
            </button>
        case "link":
            return <A
                className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                to={to}>
                {children}
            </A>
        case "a":
            return <a
                className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                href={to} target={target}>
                {children}
            </a>
    }
};