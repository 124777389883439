import React from "react";
import {Link} from "../Atoms/Link";

const Footer = () => {
    return <footer className="mt-auto w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">

        <div className="text-center">
            <div>
                <a className="flex-none text-xl font-semibold text-black dark:text-white" href="#"
                   aria-label="Brand">Хостел "СВЕЧА"</a>
            </div>


            <div className="mt-3">
                <p className="text-gray-500">ИНН 571500318842</p>
                <p className="text-gray-500">© ИП Быков Александр Викторович 2023. Все права защищены</p>
                <Link type="link" to="/contacts">Контактные данные</Link>
            </div>

        </div>

    </footer>
};

export default Footer;