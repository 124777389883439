import React, {useCallback, useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./Icons/FontAwesome/css/all.css";
import {Outlet, RouterProvider, useLocation} from "react-router";
import scrollToElement from "scroll-to-element";
import {createBrowserRouter} from "react-router-dom";
import {Provider, useDispatch, useSelector} from "react-redux";
import {initData} from "./Store/appSlice";
import CookieBanner from "./Components/PageParts/CookieBanner";
import PhoneModal from "./Components/PageParts/PhoneModal";
import store from "./Store";
import moment from "moment";
import "moment/locale/ru";
import {GridLoader} from "react-spinners";
import Hostel from "./Pages/Hostel";
import Contacts from "./Pages/Contacts";
import('preline');
moment.locale("ru");

const OutletPage = () => {
    const location = useLocation();
    const [lastKey, setLastKey] = useState(location.key);

    useEffect(() => {
        if (lastKey !== location.key) {
            console.log(lastKey);
            setLastKey(location.key);
            if (location.hash) {
                scrollToElement(location.hash);
            }
        }
    }, [lastKey, location]);

    return <Outlet/>
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <OutletPage/>,
        children: [
            {
                path: "",
                element: <App/>,
            },
            {
                path: "hostel/:id",
                element: <Hostel/>,
            },
            {
                path: "contacts",
                element: <Contacts/>,
            },
        ],
    },
]);

const RootElement = () => {
    const {loadingState} = useSelector(state => state.app);
    const dispatch = useDispatch();

    const fetchData = useCallback(async () => {
        const rawData = {
            companyData: {
                name: "Хостел \"СВЕЧА\"",
                legalEntityName: "ИП Быков Александр Викторович",
                INN: 238472738283,
                phoneNumber: "+7 (963) 757-18-27",
                workTime: "пн-вс 10:00-20:00",
                address: "МО, г. Подольск",
            },
            siteData: {
                copyrightText: "© @legalEntityName@ 2023. Все права защищены",
            },
        };
        dispatch(initData(rawData))
    }, [dispatch]);

    useEffect(() => {
        if (loadingState === "loading") {
            fetchData();
        }
    }, [loadingState, fetchData]);

    return <>
        {
            loadingState === "loading"
                ? <>
                    <div className="w-full h-full flex justify-center items-center">
                        <GridLoader color="rgb(229 231 235)" size="10em"/>
                    </div>
                </>
                : <>
                    <RouterProvider router={router}/>
                    <CookieBanner/>
                    <PhoneModal/>
                </>
        }
    </>
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RootElement/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
