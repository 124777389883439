import React from "react";
import {AnnouncementBanner} from "../Atoms/Button";
import LogoIcon from "../../Icons/Logo.png";

export const FirstScreen = ({title, subtitle, text, buttons}) => {
    return <div className="relative overflow-hidden h-screen flex flex-col justify-center">
        <div aria-hidden="true" className="flex absolute -top-96 left-1/2 transform -translate-x-1/2">
            <div
                className="bg-gradient-to-r from-violet-300/50 to-purple-100 blur-3xl w-[25rem] h-[44rem] rotate-[-60deg] transform -translate-x-[10rem] dark:from-violet-900/50 dark:to-purple-900"></div>
            <div
                className="bg-gradient-to-tl from-blue-50 via-blue-100 to-blue-50 blur-3xl w-[90rem] h-[50rem] rounded-fulls origin-top-left -rotate-12 -translate-x-[15rem] dark:from-indigo-900/70 dark:via-indigo-900/70 dark:to-blue-900/70"></div>
        </div>

        <div className="relative z-10">
            <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 lg:py-16">
                <div className="max-w-2xl text-center mx-auto">
                    <AnnouncementBanner>Работаем с юр.лицами</AnnouncementBanner>

                    <p className="inline-block mt-5 text-sm font-medium bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500 text-transparent dark:from-blue-400 dark:to-violet-400">
                        {subtitle}
                    </p>

                    <img src={LogoIcon} alt="" className="mx-auto mt-5"/>

                    <div className="mt-5 max-w-2xl">
                        <h1 className="block font-semibold text-gray-800 text-4xl md:text-5xl lg:text-6xl dark:text-gray-200">
                            {title}
                        </h1>
                    </div>

                    <div className="mt-5 max-w-3xl">
                        <p className="text-lg text-gray-600 dark:text-gray-400">{text}</p>
                    </div>

                    <div className="mt-8 grid gap-3 w-full sm:inline-flex sm:justify-center">
                        {buttons && buttons.map((button, index) => button)}
                    </div>
                </div>
            </div>
        </div>
    </div>
}