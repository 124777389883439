import React from 'react';
import {BlockHeader} from "./Typography";

export const IconList = ({icons, title, mini}) => {
    return <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <BlockHeader title={title}/>
        <div className="max-w-4xl mx-auto">
            {
                mini
                    ? <div className="flex flex-wrap justify-center">
                        {icons.map((icon) => icon)}
                    </div>
                    : <div className="grid md:grid-cols-2 gap-6 lg:gap-12">
                        <div className="space-y-6 lg:space-y-10">
                            {icons.map((icon, index) => index % 2 === 0 ? icon : null)}
                        </div>

                        <div className="space-y-6 lg:space-y-10">
                            {icons.map((icon, index) => index % 2 === 1 ? icon : null)}
                        </div>
                    </div>
            }
        </div>
    </div>
}

export const IconListItem = ({icon, title, text, mini = false}) => {
    return <div className={`flex ${mini ? `flex-col w-36` : ``}`}>
        <div
            className={`flex justify-center ${mini ? `items-center w-full h-max` : `flex-shrink-0 w-12 h-8`} mt-2 text-gray-800 dark:text-white text-5xl`}>
            {icon}
        </div>
        <div className={mini ? `mt-2` : `ml-5 sm:ml-8`}>
            <h3 className={`${mini ? `text-center` : `text-base`} sm:text-lg font-semibold text-gray-800 dark:text-gray-200`}>
                {title}
            </h3>
            {
                mini
                    ? null
                    : <p className="mt-1 text-gray-600 dark:text-gray-400">
                        {text}
                    </p>
            }
        </div>
    </div>
}