import React, {useState} from "react";
import {IconList, IconListItem} from "../Atoms/IconList";
import {CardLink} from "../Atoms/Card";

const Rules = () => {
    const previewRules = [
        <IconListItem
            icon={<i className="fa-duotone fa-ban-smoking"/>}
            title="Не курить в неположенном месте"
            text="Запрещено курить сигареты, никотиновые электронные системы и подобные предметы в общественных комнатах, в санузле и на койко-местах. Для этого предусмотренные специально отведенные места"
        />,
        <IconListItem
            icon={<i className="fa-duotone fa-wine-glass"/>}
            title="Не появляться в алкогольном и/или наркотическом опьянении"
            text="Значительная часть людей ведут себя неадекватно, когда они пьяны. Чтобы не нарушать покой жильцов, мы запрещаем появляться жильцам в алкогольном и/или наркотическом опьянении"
        />,
    ];
    const fullRules = [
        ...previewRules,
        <IconListItem
            icon={<i className="fa-duotone fa-volume-slash"/>}
            title="Соблюдайте тишину"
            text="У нас проживают жильцы, которые работают в дневную или ночную смену. У нас запрещено громко говорить или кричать, включать на полный звук телевизор или иные устройства"
        />,
        <IconListItem
            icon={<i className="fa-duotone fa-broom"/>}
            title="Соблюдайте чистоту"
            text="Мы убедительно просим убирать за собой в комнатах, на кухне и в санузле. Это нужно для того, чтобы исключить появление не приятного запаха или тараканов и клопов."
        />,
        <IconListItem
            icon={<i className="fa-duotone fa-fire"/>}
            title="Соблюдайте пожарную безопасность"
            text="Чтобы исключить возгорания в хостеле, соблюдайте простые правила пожарной безопасности: не оставляйте готовящуюся еду на плите, не оставляйте электроприборы в розетках, не используйте в комнатах мощные электроприборы (утюги и прочее)"
        />,
        <IconListItem
            icon={<i className="fa-duotone fa-gun"/>}
            title="Не проносите запрещенные предметы"
            text="Мы запрещаем проносить запрещенные и опасные предметы. К примеру: оружие, наркотики, химикаты и реагенты"
        />,
        <IconListItem
            icon={<i className="fa-duotone fa-person-simple"/>}
            title="Посторонним вход воспрещен"
            text="Нельзя проводит посторонних людей в хостел, дабы избежать неприятных моментов с их участием (кража или конфликты)"
        />,
        <IconListItem
            icon={<i className="fa-duotone fa-rotate"/>}
            title="Не переселяйтесь"
            text="Запрещено переселяться на другие койко-места без разрешения администратора"
        />,
    ];

    const [rules, setRules] = useState(previewRules);
    const [isExpand, setIsExpand] = useState(false);

    return <>
        <IconList
            title="Наши правила"
            icons={rules}
        />
        {
            !isExpand
                ? <CardLink><button
                    className="inline-flex items-center gap-x-1.5 text-blue-600 decoration-2 hover:underline font-medium"
                    onClick={() => {
                        setRules(fullRules);
                        setIsExpand(true)
                    }}
                >
                    Показать все правила
                </button></CardLink>
                : null
        }
    </>
};

export default Rules;