import {createSlice} from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const appSlice = createSlice({
    name: "app",
    initialState: {
        cookieAllow: false,
        modals: {
            cookiePopupShow: false,
            phoneModalShow: false,
            calcModalShow: false,
        },
        loadingState: "loading",
        companyData: {
            name: "",
            legalEntityName: "",
            INN: 0,
            phoneNumber: "",
            workTime: "",
            address: "",
        },
        siteData: {
            copyrightText: "",
        },
    },
    reducers: {
        initData: (state, action) => {
            const {companyData, siteData} = action.payload;

            state.companyData = companyData;
            state.siteData = siteData;
            state.cookieAllow = Cookies.get("cookieAllow") === "true";
            state.loadingState = "ok";
        },
        showModal: (state, action) => {
            state.modals = Object.assign(state.modals, action.payload);
        },
        cookieAllow: state => {
            Cookies.set("cookieAllow", "true");
            state.cookieAllow = true;
            state.modals.cookiePopupShow = false;
        },
    },
});

export const {initData, showModal, cookieAllow} = appSlice.actions;

export default appSlice.reducer;