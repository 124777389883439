import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {showModal} from "../../Store/appSlice";
import momentTZ from "moment-timezone";
import moment from "moment";

const PhoneModal = () => {
    const show = useSelector(state => state.app.modals.phoneModalShow);
    const [currentTime, setCurrentTime] = useState(momentTZ.tz("Europe/Moscow"));
    const [isEnableCall, setIsEnableCall] = useState(moment(currentTime).isBetween(moment("08:00:00", "HH:mm:ss"), moment("20:00:00", "HH:mm:ss"), "m"));
    const dispatch = useDispatch();

    useEffect(() => {
        // setTimeout(() => dispatch(showModal({phoneModalShow: true})), 1000 * 30);
        const timer = setInterval(() => {
            setCurrentTime(momentTZ.tz("Europe/Moscow"));
            setIsEnableCall(moment(currentTime).isBetween(moment("08:00:00", "HH:mm:ss"), moment("20:00:00", "HH:mm:ss"), "m"));
        }, 100);

        return () => clearInterval(timer);
    }, [currentTime, dispatch]);

    const handleClose = () => {
        dispatch(showModal({phoneModalShow: false}));
    };

    return <div
        className={`fixed z-50 w-screen h-full top-0 left-0 flex justify-center items-center bg-black bg-opacity-50 transition-all ${show ? `flex opacity-100` : `hidden opacity-0`}`}>
        <div
            className={`w-full lg:w-max h-full lg:h-max flex flex-col justify-between lg:justify-center items-center text-center border shadow-sm lg:rounded-xl p-4 md:p-5 dark:bg-blue-500 dark:bg-clip-padding dark:backdrop-filter dark:backdrop-blur-lg dark:bg-opacity-10 dark:border-gray-700 dark:shadow-slate-700/[.7] dark:text-gray-400 transition-all ${show ? `scale-100` : `-scale-150`}`}>
            <div className="w-full">
                {
                    isEnableCall
                        ? <>
                            <p className="text-2xl">Позвоните!</p>
                            <p className="text-base">Если вас заинтересовало заселение в наши хостелы</p>
                            <p className="text-2xl font-bold">+7 (963) 757-18-27</p>
                        </>
                        : <>
                            <p className="text-2xl">Сейчас мы не работаем</p>
                            <p className="text-base">Но мы рады получить от вас звонок в рабочее время</p>
                            <p>Откроемся {currentTime.to(moment().add(1, "d").hour(10).minute(0).second(0))}</p>
                        </>
                }
                <p className="text-sm italic">пн-вс 08:00-20:00</p>
            </div>

            <div className="w-full">
            <div className="flex flex-col pt-2 w-full gap-2">
                {
                    isEnableCall
                        ? <button type="button"
                                  className="w-full py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">
                            Позвонить
                        </button>
                        : null
                }
                <button type="button"
                        className="w-full py-2 px-3 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-blue-500 hover:text-white hover:bg-blue-500 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:border-gray-700 dark:hover:border-blue-500"
                            onClick={handleClose}
                    >
                        Закрыть
                    </button>
                </div>
            </div>
        </div>
    </div>
};

export default PhoneModal;