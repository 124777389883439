import React from "react";
import {Button} from "../Components/Atoms/Button";
import Footer from "../Components/PageParts/Footer";

const Contacts = () => {
    return <>
        <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
            <h2 className="text-2xl lg:text-4xl font-bold text-white mb-5 flex items-center">
                <Button type="link" to="/" variant="outline" className="mr-2">
                    <i className="fa-duotone fa-house"></i>
                </Button>
                Контактные данные
            </h2>
            <h4 className="text-lg lg:text-2xl font-bold text-white mb-2">ИП Быков Александр Викторович</h4>
            <h4 className="text-md lg:text-xl font-bold text-white mb-2">Полное наименование: ИНДИВИДУАЛЬНЫЙ
                ПРЕДПРИНИМАТЕЛЬ
                БЫКОВ АЛЕКСАНДР ВИКТОРОВИЧ</h4>
            <h4 className="text-md lg:text-xl font-bold text-white mb-2">Юридический адрес организации: 303814, РОССИЯ,
                ОРЛОВСКАЯ ОБЛ, ЛИВЕНСКИЙ Р-Н, С КОЗЬМИНКА, УЛ БЕРЕЗОВАЯ, Д 13</h4>
            <h4 className="text-md lg:text-xl font-bold text-white mb-2">ИНН: 571500318842</h4>
            <h4 className="text-md lg:text-xl font-bold text-white mb-2">ОГРНИП: 323570000018430</h4>
            <h4 className="text-md lg:text-xl font-bold text-white mb-2">Номер телефона: +7 (915) 192-94-83</h4>
        </div>
        <Footer/>
    </>
};

export default Contacts;