import React, {useEffect, useState} from "react";
import {FirstScreen} from "./Components/PageParts/FirstScreen";
import {IconList, IconListItem} from "./Components/Atoms/IconList";
import {Card, CardLink} from "./Components/Atoms/Card";
import Footer from "./Components/PageParts/Footer";
import CookieBanner from "./Components/PageParts/CookieBanner";
import PhoneModal from "./Components/PageParts/PhoneModal";
import Rules from "./Components/PageParts/Rules";
import {useDispatch} from "react-redux";
import {showModal} from "./Store/appSlice";
import {Link} from "./Components/Atoms/Link";
import {Price} from "./Components/Atoms/Price";
import {BlockHeader} from "./Components/Atoms/Typography";
import {Button} from "./Components/Atoms/Button";

const App = () => {
    const dispatch = useDispatch();

    const showPhoneModal = () => dispatch(showModal({phoneModalShow: true}));

    return <div className="App">
        <FirstScreen
            subtitle="МО, г. Подольск"
            title="Хостел Свеча"
            text="Комфортабельные и уютные хостелы. В
                            близости от важных маршрутов общественного транспорта и городской инфраструктуры. Низкие
                            цены и быстрое заселение."
            buttons={[
                <Button variant="gradient" onClick={showPhoneModal}>
                    <i className="fa-solid fa-phone"/>
                    Позвонить
                </Button>
            ]}
        />

        <IconList
            title="Удобства у нас"
            mini={true}
            icons={[
                <IconListItem
                    icon={<i className="fa-duotone fa-square-0"/>}
                    title="Нет доп.услуг"
                    mini={true}
                    text="У нас отсутствует доп.услуги, всё, что есть в хостеле, входит в стоимость аренды койко-места"
                />,
                <IconListItem
                    icon={<i className="fa-duotone fa-washing-machine"/>}
                    title="Санузел"
                    mini={true}
                    text="В каждом хостеле присутствует стиральная машина, туалет и душ, которыми можно пользоваться бесплатно"
                />,
                <IconListItem
                    icon={<i className="fa-duotone fa-oven"/>}
                    title="Кухня"
                    mini={true}
                    text="В каждом хостеле присутствует кухня с плитой, микроволновкой, чайником, столом для приготовления, телевизором и местом для приеме пищи"
                />,
                <IconListItem
                    icon={<i className="fa-duotone fa-refrigerator"/>}
                    title="Холодильник"
                    mini={true}
                    text="Так же у нас в кухне присутствуют холодильники. За продукты в нем не стоит переживать, они находятся под видеонаблюдением"
                />,
                <IconListItem
                    icon={<i className="fa-duotone fa-camera-cctv"/>}
                    title="Видео-наблюдение"
                    mini={true}
                    text="Все общественные помещения в хостеле под видеонаблюдением. Это позволяет сохранять порядок и тишину в хостеле"
                />,
                <IconListItem
                    icon={<i className="fa-duotone fa-lock-keyhole"/>}
                    title="Кодовые замки на входе"
                    mini={true}
                    text="Чтобы препятствовать входу в хостел посторонних лиц, мы установили кодовые замки на входе каждого хостела"
                />,
            ]}
        />

        <IconList
            title="Удобная оплата"
            mini={true}
            icons={[
                <IconListItem
                    icon={<i className="fa-duotone fa-credit-card"/>}
                    title="Банковская карта"
                    text="Мы принимаем онлайн-платежи с банковских дебетовых и кредитных карт, таких платежных систем, как МИР, MasterCard, VISA и UnionPay"
                    mini={true}
                />,
                <IconListItem
                    icon={<i className="fa-duotone fa-qrcode"/>}
                    title="СБП"
                    text="У нас так же можно оплатить через QR-код СПБ"
                    mini={true}
                />,
                <IconListItem
                    icon={<i className="fa-duotone fa-money-check-pen"/>}
                    title="Оплата через счёт"
                    text="Мы так же работаем и с юридическими лицами. Готовы в любой момент выставить счёт на любое количество мест и времени"
                    mini={true}
                />,
                <IconListItem
                    icon={<i className="fa-duotone fa-receipt"/>}
                    title="Чеки"
                    text="У нас вся оплата проходит официально. При необходимости, мы можем предоставить чек об оплате"
                    mini={true}
                />,
            ]}
        />

        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <BlockHeader title="Наши хостелы" subtitle="Мы постарались расположить их недалеко от
                    магазинов, ТЦ, больниц, остановок и прочего"/>

            <div
                className="flex flex-col lg:flex-row flex-wrap lg:justify-center items-left lg:space-x-6 space-y-1 [&>.card]:w-full lg:[&>.card]:w-1/4 mb-10">
                <Card
                    title="Мужской хостел" subtitle="МО, г. Подольск, ул. Юбилейная, 1\1"
                >
                    {/*// <Price time="в неделю">2500</Price>*/}
                    <Link type="link" to="/hostel/1">
                        Подробнее
                        <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path
                                d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                    </Link>
                </Card>
                <Card
                    title="Женский хостел" subtitle="МО, г. Подольск, ул. Б. Серпуховская, 57"
                >
                    {/*// <Price time="в неделю">2400</Price>*/}
                    <Link type="link" to="/hostel/2">
                        Подробнее
                        <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path
                                d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                    </Link>
                </Card>
            </div>

            <CardLink>
                <p className="text-gray-600 dark:text-gray-400">
                    Готовы заселиться?
                </p>
                <Link onClick={showPhoneModal}>
                    Звоните!
                    <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </Link>
            </CardLink>
        </div>

        <Rules/>

        <Footer/>
    </div>
}

export default App;
