import React from "react";

export const BlockHeader = ({title, subtitle}) => <div className="max-w-2xl text-center mx-auto mb-10 lg:mb-14">
    <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">{title}</h2>
    {subtitle ? <p className="mt-1 text-gray-600 dark:text-gray-400">{subtitle}</p> : null}
</div>;

export const Header = ({children, className}) => <h2
    className={"text-2xl font-bold md:text-4xl md:leading-tight dark:text-white " + className}>{children}</h2>

export const MiniText = ({children, className}) => <p className={"mt-2 text-xs uppercase text-gray-600 dark:text-gray-400" + className}>
    {children}
</p>