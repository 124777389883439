import React from 'react';

export const Card = ({image, title, subtitle, children, link}) => {
    return <div
        className="group flex flex-col bg-white border shadow-sm rounded-xl hover:shadow-md transition dark:bg-slate-900 dark:border-gray-800 card"
    >
        {
            image && <div className="aspect-w-16 aspect-h-9"><img
                className="w-full h-full object-cover rounded-t-xl" src={image} alt={title}/>
            </div>
        }
        <div className="p-4 md:p-5">
            <p className="mt-2 text-xs uppercase text-gray-600 dark:text-gray-400">
                {subtitle}
            </p>
            <h3 className="mt-2 text-lg font-medium text-gray-800 group-hover:text-blue-600 dark:text-gray-300 dark:group-hover:text-white">
                {title}
            </h3>
            {children}
        </div>
    </div>
}

export const CardLink = ({children}) => {
    return <div className="text-center">
        <div
            className="inline-block bg-white border shadow-sm rounded-full dark:bg-slate-900 dark:border-gray-800">
            <div className="py-3 px-4 flex items-center gap-x-2">
                {children}
            </div>
        </div>
    </div>
}