export const db = {
    hostels: [
        {
            id: 1,
            name: "Мужской хостел",
            desc: "Сдаются койка-места в уютном, чистом хостеле! Все для проживания есть! Три санузла, постельное белье, посуда, стиральная машинка, вай-фай - все входит в сумму проживания! Очень удобно для тех, кто работает на складах Wildberries, пять минут до остановки.",
            address: "МО, г. Подольск, ул. Юбилейная, 1\\1",
            price: {
                1: 500,
                7: 2500,
                30: 11500,
            },
            yandexMapLink: "https://yandex.ru/maps/10747/podolsk/?ll=37.498779%2C55.418228&mode=poi&poi%5Bpoint%5D=37.498683%2C55.418222&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D213766171252&z=18",
            yandexGoLink: "https://3.redirect.appmetrica.yandex.com/route?end-lat=55.418228&end-lon=37.498779&appmetrica_tracking_id=25395763362139037",
            twoGISLink: "https://2gis.ru/podolsk/geo/4504235283110768/37.498781%2C55.418251?m=37.499035%2C55.418031%2F18.75%2Fp%2F38.39%2Fr%2F-64.21",
            routes: [
                {
                    title: "От станции \"Подольск\" до остановки \"Фетищевская улица\"",
                    walkTime: 5,
                    buses: [1033, 1028, 1036, 1050, 1077, "с924", 24, 1034, 1026, 1034, 1047],
                    image: require("./Images/Routes/Male0.jpeg"),
                    type: "D2",
                    routeLink: "https://yandex.ru/maps/10747/podolsk/?ll=37.546330%2C55.429115&mode=routes&rtext=55.431664%2C37.562833~55.418420%2C37.499624&rtt=mt&ruri=ymapsbm1%3A%2F%2Ftransit%2Fstop%3Fid%3D2084766424~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjkzMzYwORJo0KDQvtGB0YHQuNGPLCDQnNC-0YHQutC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0J_QvtC00L7Qu9GM0YHQuiwg0K7QsdC40LvQtdC50L3QsNGPINGD0LvQuNGG0LAsIDHQujEiCg2d_xVCFXasXUI%2C&z=13.53",
                },
                {
                    title: "От станции \"Подольск\" до остановки \"Архив Министерства обороны\"",
                    walkTime: 12,
                    buses: [21],
                    trolleybuses: [1, 2, 5],
                    image: require("./Images/Routes/Male1.jpeg"),
                    type: "D2",
                    routeLink: "https://yandex.ru/maps/10747/podolsk/?ll=37.546330%2C55.429115&mode=routes&rtext=55.431664%2C37.562833~55.418420%2C37.499624&rtt=mt&ruri=ymapsbm1%3A%2F%2Ftransit%2Fstop%3Fid%3D2084766424~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjkzMzYwORJo0KDQvtGB0YHQuNGPLCDQnNC-0YHQutC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0J_QvtC00L7Qu9GM0YHQuiwg0K7QsdC40LvQtdC50L3QsNGPINGD0LvQuNGG0LAsIDHQujEiCg2d_xVCFXasXUI%2C&z=13.53",
                },
                {
                    title: "От станции метро \"Бульвар Дмитрия Донского\" до остановки \"Фетищевская улица\"",
                    walkTime: 12,
                    buses: [1004, 520],
                    trolleybuses: ["1246к"],
                    image: require("./Images/Routes/Male0.jpeg"),
                    type: "metro",
                    routeLink: "https://yandex.ru/maps/10747/podolsk/?ll=37.543071%2C55.492921&mode=routes&rtext=55.569657%2C37.577324~55.418420%2C37.499624&rtt=mt&ruri=ymapsbm1%3A%2F%2Ftransit%2Fstop%3Fid%3Dstation__9858795~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjkzMzYwORJo0KDQvtGB0YHQuNGPLCDQnNC-0YHQutC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0J_QvtC00L7Qu9GM0YHQuiwg0K7QsdC40LvQtdC50L3QsNGPINGD0LvQuNGG0LAsIDHQujEiCg2d_xVCFXasXUI%2C&z=11.98",
                },
            ],
        },
        {
            id: 2,
            name: "Женский хостел",
            desc: "Сдаются койка-места в новом, только что открытом хостеле! Расположение очень удобное, рядом с центром города. Магазины, Ашан, Пятёрочка, столовая, остановка - все в шаговой доступности. АЛКОГОЛЬ СТРОГО ЗАПРЕЩЕН. Все для проживания есть - микроволновка, стиральная машинка, вайфай - всё входит в сумму проживания. Ждём порядочных жильцов!",
            address: "МО, г. Подольск, ул. Б. Серпуховская, 57",
            price: {
                1: 500,
                7: 2400,
                30: 11500,
            },
            yandexMapLink: "https://yandex.ru/maps/10747/podolsk/house/bolshaya_serpukhovskaya_ulitsa_57/Z04YcwJgS0QAQFtvfXhwdX1hZw==/?ll=37.550380%2C55.414082&z=19.41",
            yandexGoLink: "https://3.redirect.appmetrica.yandex.com/route?end-lat=55.414091&end-lon=37.550275&appmetrica_tracking_id=25395763362139037",
            twoGISLink: "https://2gis.ru/podolsk/geo/4504235282823972/37.550212%2C55.41412?m=37.550264%2C55.413954%2F19.04",
            routes: [
                {
                    title: "От станции \"Подольск\" до остановки \"Автоколонна № 1127\"",
                    walkTime: 4,
                    buses: [38, 44, 49, 7, 3, 35, 29, 1030, 51, 61, 67, 71, 13],
                    miniBuses: ["7к", "3к", "29к", "35к", 52, "13к"],
                    image: require("./Images/Routes/Female0.jpeg"),
                    type: "D2",
                    routeLink: "https://yandex.ru/maps/10747/podolsk/?ll=37.554735%2C55.423208&mode=routes&rtext=55.431664%2C37.562833~55.414123%2C37.550217&rtt=mt&ruri=ymapsbm1%3A%2F%2Ftransit%2Fstop%3Fid%3D2084766424~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjYwOTY5MhJ70KDQvtGB0YHQuNGPLCDQnNC-0YHQutC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwg0J_QvtC00L7Qu9GM0YHQuiwg0JHQvtC70YzRiNCw0Y8g0KHQtdGA0L_Rg9GF0L7QstGB0LrQsNGPINGD0LvQuNGG0LAsIDU3IgoNbTMWQhUQqF1C&z=15.06",
                },
            ],
        },
    ],
};