import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {db} from "../db";
import {Button} from "../Components/Atoms/Button";
import {Price} from "../Components/Atoms/Price";
import {Accordion, AccordionItem} from "../Components/Atoms/Accordion";
import {Kbd} from "../Components/Atoms/Kbd";
import MSKMetroD2Icon from "../Icons/MSK_Metro_D2.png";
import MSKMetroIcon from "../Icons/MSK_Metro.png";
import Footer from "../Components/PageParts/Footer";

const Hostel = () => {
    const {id} = useParams();

    const calc = (days, costs) => (((days - (days % 30)) / 30) * costs[30]) + ((((days % 30) - ((days % 30) % 7)) / 7) * costs[7]) + (((days % 30) % 7) * costs[1]);

    const hostel = db.hostels.find(h => h.id === Number(id));
    const [days, setDays] = useState(7);
    const [price, setPrice] = useState(calc(7, hostel.price));

    const changeValue = val => setDays(validate(Number(days) + val));
    const validate = val => val < 1 ? 1 : val;

    useEffect(() => {
        setPrice(calc(days, hostel.price));
    }, [days, hostel.price]);

    return <>
        <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
            <h2 className="text-2xl lg:text-4xl font-bold text-white mb-5 flex items-center"><Button type="link" to="/"
                                                                                                     variant="outline"
                                                                                                     className="mr-2"><i
                className="fa-duotone fa-house"></i></Button> {hostel.name}</h2>
            <p className="text-md lg:text-lg font-bold text-white">
                <i className="fa-duotone fa-location-dot mr-2"></i> {hostel.address}
            </p>
            <div className="flex mt-5 gap-5 flex-wrap">
                <Button variant="default"><i
                    className="fa-duotone fa-phone"></i> Позвонить</Button>
                <Button variant="default" type="a" to={hostel.yandexMapLink} className="bg-red-500 hover:bg-red-700"><i
                    className="fa-brands fa-yandex-international"></i> Открыть
                    в Я.Картах</Button>
                <Button variant="default" type="a" to={hostel.yandexGoLink}
                        className="bg-yellow-500 hover:bg-yellow-700 text-black"><i
                    className="fa-duotone fa-taxi"></i> Открыть
                    в Я.Такси</Button>
                <Button variant="default" type="a" to={hostel.twoGISLink} className="bg-green-500 hover:bg-green-700"><i
                    className="fa-duotone fa-map"/> Открыть
                    в 2ГИС</Button>
            </div>
            <p className="mt-5 text-white">
                {hostel.desc}
            </p>
            {/*<div className="flex flex-col lg:flex-row mt-5 w-full justify-center lg:justify-between">*/}
            {/*//     <Price time="за сутки" timeTop>{hostel.price[1]}</Price>*/}
            {/*//     <Price time="за неделю" timeTop>{hostel.price[7]}</Price>*/}
            {/*//     <Price time="за месяц" timeTop>{hostel.price[30]}</Price>*/}
            {/*// </div>*/}

            {/*<div*/}
            {/*    className="py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-slate-900 dark:border-gray-700">*/}
            {/*    <div className="w-full flex justify-between items-center gap-x-3">*/}
            {/*        <div>*/}
            {/*        <span className="block font-medium text-sm text-gray-800 dark:text-white">*/}
            {/*            Расчет стоимости (кол-во дней)*/}
            {/*        </span>*/}
            {/*            <span className="block text-xs text-gray-500 dark:text-gray-400">*/}
            {/*            {price} за этот период {days * hostel.price[1] > price ? <span className="text-green-500">выгода ~{(100 - (price * 100) / (days * hostel.price[1])).toFixed(0)}%</span> : ""}*/}
            {/*        </span>*/}
            {/*        </div>*/}
            {/*        <div className="flex items-center gap-x-1.5">*/}
            {/*            <button type="button" onClick={() => changeValue(-1)}*/}
            {/*                    className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">*/}
            {/*                <svg className="flex-shrink-0 w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24"*/}
            {/*                     height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"*/}
            {/*                     stroke-linecap="round" stroke-linejoin="round">*/}
            {/*                    <path d="M5 12h14"/>*/}
            {/*                </svg>*/}
            {/*            </button>*/}

            {/*            <input*/}
            {/*                className="p-0 w-6 bg-transparent border-0 text-gray-800 text-center focus:ring-0 dark:text-white"*/}
            {/*                type="text" data-hs-input-number-input onInput={e => setDays(validate(e.target.value))}*/}
            {/*                value={days}/>*/}

            {/*            <button type="button" onClick={() => changeValue(1)}*/}
            {/*                    className="w-6 h-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">*/}
            {/*                <svg className="flex-shrink-0 w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24"*/}
            {/*                     height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"*/}
            {/*                     stroke-linecap="round" stroke-linejoin="round">*/}
            {/*                    <path d="M5 12h14"/>*/}
            {/*                    <path d="M12 5v14"/>*/}
            {/*                </svg>*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    {*/}
            {/*        (days % 7 === 6 || days % 7 === 5) && calc(7, hostel.price) >= hostel.price[7]*/}
            {/*            ? <div className="bg-blue-600 text-sm text-white rounded-lg p-4 dark:bg-blue-500 mt-2"*/}
            {/*                   role="alert">*/}
            {/*                <span className="font-bold">Можно выгоднее!</span> Возмите*/}
            {/*                на {days % 7 === 6 ? "один день" : "два дня"} больше и получите больше выгоды*/}
            {/*            </div>*/}
            {/*            : null*/}
            {/*    }*/}
            {/*</div>*/}

            <h2 className="text-xl lg:text-2xl font-bold text-white mt-5">Как до нас добраться?</h2>
            <Accordion>
                {
                    hostel.routes.map((r, i) => <AccordionItem title={<>
                        {r.type === "D2" ? <img src={MSKMetroD2Icon} className="w-7" alt=""/> : null}
                        {r.type === "metro" ? <img src={MSKMetroIcon} className="w-7" alt=""/> : null}
                        {r.title}
                    </>} defaultActive={i === 0}>
                        <Button variant="default" type="a" to={r.routeLink}
                                className="bg-red-500 hover:bg-red-700 w-full mb-2"><i
                            className="fa-duotone fa-route"></i> Открыть
                            маршрут
                            в Я.Картах</Button>
                        <Kbd className="mr-1 mb-1">пешком {r.walkTime} минут</Kbd><br/>
                        <p className="text-md text-white">Автобусы:</p>
                        {r.buses.map(b => <Kbd className="mr-1 mb-1">{b}</Kbd>)}
                        {
                            r.miniBuses
                                ? <>
                                    <p className="text-md text-white">Маршрутки:</p>
                                    {r.miniBuses.map(b => <Kbd className="mr-1 mb-1">{b}</Kbd>)}
                                </>
                                : null
                        }
                        {
                            r.trolleybuses
                                ? <>
                                    <p className="text-md text-white">Троллейбусы:</p>
                                    {r.trolleybuses.map(b => <Kbd className="mr-1 mb-1">{b}</Kbd>)}
                                </>
                                : null
                        }
                        <img src={r.image} alt="" className="mt-2 rounded-2xl"/>
                    </AccordionItem>)
                }
            </Accordion>
        </div>
        <Footer/>
    </>
};

export default Hostel;